export const inquireColumns = () => {
  return [
    {
      title: '序号',
      width: '70',
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '巡检人',
      dataIndex: 'user.name',
      align: 'center',
      scopedSlots: { customRender: 'duration' },
    },
    {
      title: '所属部门',
      dataIndex: 'department.name',
      align: 'center',
      scopedSlots: { customRender: 'duration' },
    },
    {
      title: '巡检时间',
      align: 'center',
      customRender: (text, record) => {
        const time = `${record.startTime  ? record.startTime : ''} ~ ${record.endTime  ? record.endTime : ''}`;//es6写法
        return time;
      }
    },
    {
      title: '巡检时长',
      dataIndex: 'durationStr',
      align: 'center',
    },
    {
      title: '巡检公里',
      dataIndex: 'mileage',
      align: 'center',
    },
    {
      title: '巡检结果',
      dataIndex: 'result',
      align: 'center',
      scopedSlots: { customRender: 'result' },
    },
    {
      title: '巡检状态',
      dataIndex: 'finish',
      align: 'center',
      scopedSlots: { customRender: 'finish' },
    },
    {
      title: '绑定车辆',
      dataIndex: 'carNumber',
      align: 'center',
      customRender: (text, record) => {
        const carNumber = `${record.carNumber  ? record.carNumber : '未绑定'}`;//es6写法
        return carNumber;
      }
    },
    {
      title: '巡检设备数量',
      dataIndex: 'totalAmount',
      align: 'center',
    },
    {
      title: '正常设备数量',
      dataIndex: 'normalAmount',
      align: 'center',
    },
    {
      title: '异常设备数量',
      dataIndex: 'abnormalAmount',
      align: 'center',
    },
  ]
}

// 巡检结果
export const inspectionResult  = function () {
  return [
    { value: 'ABNORMAL', label: '正常' },
    { value: 'NOT_CHECK', label: '异常' }
  ]
}